import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" {...props}>
      <path
        d="M56.189 140.714a36.826 36.826 0 0 1-31.903-18.425L4.937 88.782a36.837 36.837 0 0 1 0-36.844l19.35-33.514A36.824 36.824 0 0 1 56.189 0h38.7a36.84 36.84 0 0 1 31.908 18.425l19.35 33.514a36.87 36.87 0 0 1 0 36.844l-19.35 33.507a36.841 36.841 0 0 1-31.908 18.425h-38.7zM417.117 140.714a36.853 36.853 0 0 1-31.915-18.425l-19.35-33.507a36.87 36.87 0 0 1 0-36.844l19.35-33.514A36.854 36.854 0 0 1 417.117 0h38.693a36.826 36.826 0 0 1 31.903 18.425l19.349 33.514a36.837 36.837 0 0 1 0 36.844l-19.349 33.507a36.825 36.825 0 0 1-31.903 18.425h-38.693z"
        style={{
          fill: '#231815',
        }}
      />
      <path
        d="M245.229 39.581h-76.634A46.487 46.487 0 0 0 129.89 60.29L46.9 184.778a46.498 46.498 0 0 0-7.813 25.804v117.274a46.514 46.514 0 0 0 21.572 39.263l65.471 41.602a46.472 46.472 0 0 0 24.934 7.256H254.543V39.581h-9.314zM465.1 184.778 382.11 60.29a46.499 46.499 0 0 0-38.705-20.709h-88.862v376.396H360.93a46.462 46.462 0 0 0 24.94-7.256l65.471-41.602a46.517 46.517 0 0 0 21.579-39.263V210.582a46.51 46.51 0 0 0-7.82-25.804z"
        style={{
          fill: '#f1f2f1',
        }}
      />
      <path
        d="M256 296.284v-90.748h-35.521c-16.117 0-30.476 9.442-35.797 23.532l-28.302 74.938a32.716 32.716 0 0 0 .08 23.422l16.227 42.11c5.389 13.98 19.686 23.318 35.724 23.318H256v-96.572z"
        style={{
          fill: '#dce1e1',
        }}
      />
      <path
        d="m355.615 304.006-28.296-74.938c-5.327-14.09-19.68-23.532-35.797-23.532H256v187.32h47.597c16.025 0 30.317-9.338 35.711-23.318l16.221-42.11a32.653 32.653 0 0 0 .086-23.422z"
        style={{
          fill: '#d3d3d3',
        }}
      />
      <path
        d="M264.089 351.29c19.718 0 35.712-15.994 35.712-35.718 0-19.723-15.994-35.711-35.712-35.711H256v71.429h8.089zM212.199 315.573c0 19.723 15.982 35.718 35.712 35.718H256v-71.429h-8.089c-19.729-.001-35.712 15.987-35.712 35.711z"
        style={{
          fill: '#3e3a39',
        }}
      />
      <path
        d="M141.016 142.741a17.437 17.437 0 0 1 16.239-11.065h40.683c4.917 0 9.608 2.082 12.921 5.719a17.491 17.491 0 0 1 4.446 13.416l-7.893 81.257a17.37 17.37 0 0 1-3.852 9.338l-32.448 39.759a17.444 17.444 0 0 1-13.514 6.411H122.61a17.46 17.46 0 0 1-15.107-8.72L91.46 251.087a17.431 17.431 0 0 1 .857-18.78l29.263-41.467a17.414 17.414 0 0 0 1.99-3.686l17.446-44.413zM370.984 142.741a17.457 17.457 0 0 0-16.24-11.065h-40.689a17.463 17.463 0 0 0-12.914 5.719 17.468 17.468 0 0 0-4.446 13.416l7.893 81.257a17.408 17.408 0 0 0 3.846 9.338l32.448 39.759a17.448 17.448 0 0 0 13.521 6.411h34.983c6.233 0 11.99-3.325 15.106-8.72l16.049-27.769a17.431 17.431 0 0 0-.857-18.78l-29.276-41.467a17.57 17.57 0 0 1-1.971-3.686l-17.453-44.413z"
        style={{
          fill: '#231815',
        }}
      />
      <path
        d="M205.231 373.524h101.538v10.256H205.231z"
        style={{
          fill: '#b5b5b5',
        }}
      />
    </svg>
  )
}

export default SVG

// cmd is for doraemon usage

const themeMeta = {
  light: {
    title: 'light',
    desc: 'light theme',
    slug: 'light',
    cmd: 'theme',
  },
}

export default themeMeta

import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className="iconify iconify--twemoji"
      viewBox="0 0 36 36"
      {...props}
    >
      <path fill="#DD2E44" d="M29.192 36v-1a6 6 0 0 0-6-6H11.808a5 5 0 0 0-5 5v2h22.384z" />
      <path
        fill="#292F33"
        d="M9 18c0 4.971 4.029 5 9 5s9-.029 9-5c0-.153-4.563-.998-9-1-4.562-.002-9 .843-9 1z"
      />
      <path fill="#F7DECE" d="m14.587 29.169 3.414 1.493 3.415-1.493v-3.415h-6.829z" />
      <path
        fill="#EEC2AD"
        d="M14.587 27.558c1.038 1.173 2.011 1.467 3.411 1.467 1.399 0 2.379-.295 3.418-1.467v-3.414h-6.829v3.414z"
      />
      <path
        fill="#292F33"
        d="M20.76 9.97c-1.476-.478-4.95-.347-5.818.782-2.258.043-4.907 2.084-5.254 4.776-.344 2.665.422 3.902.695 5.905.309 2.27 1.585 2.996 2.605 3.3 1.468 1.939 3.028 1.856 5.648 1.856 5.116 0 7.553-3.423 7.769-9.238.13-3.517-1.934-6.18-5.645-7.381z"
      />
      <path
        fill="#F7DECE"
        d="M23.686 17.6c-.495-.685-1.129-1.237-2.518-1.433.521.239 1.02 1.064 1.086 1.52.065.456.13.825-.282.369-1.653-1.827-3.452-1.107-5.236-2.223-1.246-.779-1.625-1.641-1.625-1.641s-.152 1.151-2.041 2.323c-.548.34-1.201 1.097-1.563 2.214-.261.803-.18 1.52-.18 2.744 0 3.574 2.945 6.578 6.578 6.578s6.578-3.031 6.578-6.578c0-2.223-.233-3.092-.797-3.873z"
      />
      <path
        fill="#C1694F"
        d="M18.636 23.3h-1.462a.364.364 0 1 1 0-.73h1.462a.364.364 0 1 1 0 .73z"
      />
      <path
        fill="#662113"
        d="M14.981 20.742a.73.73 0 0 1-.731-.731v-.731a.73.73 0 1 1 1.462 0v.731a.731.731 0 0 1-.731.731zm5.848 0a.73.73 0 0 1-.731-.731v-.731a.73.73 0 1 1 1.462 0v.731a.733.733 0 0 1-.731.731z"
      />
      <path
        fill="#C1694F"
        d="M18.001 26.352c-2.112 0-2.76-.541-2.869-.65a.503.503 0 0 1 .692-.729c.04.028.553.374 2.177.374 1.687 0 2.175-.372 2.179-.376a.49.49 0 0 1 .7.011.514.514 0 0 1-.011.72c-.107.109-.756.65-2.868.65"
      />
      <path
        fill="#292F33"
        d="M27 18V6c0-3.3-2.7-6-6-6h-6c-3.3 0-6 2.7-6 6v12h18zM12.943 30.461l-4.34.737c-.253.043-.493-.144-.533-.416l-.146-.988c-.04-.272.134-.529.388-.572l4.34-.737c.253-.043.493.144.534.416l.146.988c.039.271-.135.529-.389.572zm10.138 0 4.34.737c.253.043.493-.144.534-.416l.146-.988c.04-.272-.134-.529-.388-.572l-4.34-.737c-.253-.043-.493.144-.534.416l-.146.988c-.04.271.135.529.388.572z"
      />
      <path
        fill="#99AAB5"
        d="M11.875 18s-.174 9.414 6.113 9.414S24.125 18 24.125 18H25s-.307 10.461-7 10.461S11 18 11 18h.875z"
      />
      <path fill="#F5F8FA" d="M17.5 31h1v5h-1z" />
      <path
        fill="#292F33"
        d="M21.38 27.816C21.38 29.391 19.144 30 18 30s-3.38-.609-3.38-2.184c0 0-.62-.127-.62 1.184s1.888 3 4 3 4-1.689 4-3-.62-1.184-.62-1.184z"
      />
    </svg>
  )
}

export default SVG

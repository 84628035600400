const GUIDE = {
  HOME: 'HOME',
  DEFAULT: 'DEFAULT',
  FAME_PEOPLE: 'FAME_PEOPLE',
  DEVELOPER: 'DEVELOPER',
  IMAGE: 'IMAGE',
  SNIPPETS_LIST: 'SNIPPETS_LIST',
  SNIPPETS_MASONRY: 'SNIPPETS_MASONRY',
}

export default GUIDE

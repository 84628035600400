const ERR = {
  GRAPHQL: 'GRAPHQL',
  PARSE_CHEATSHEET_MD: 'PARSE_CHEATSHEET_MD',
  NETWORK: 'NETWORK',
  NOT_FOUND: 'NOT_FOUND',
  TIMEOUT: 'TIMEOUT',
  AUTH: 'AUTH',
  UNKOWN: 'UNKOWN',
}

export default ERR
